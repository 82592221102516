
.owl-carousel{
  overflow:hidden;
}
.owl-theme{
  .owl-item{
    overflow:hidden;
    img{
      width:auto; // original width 100% - is a problem if responsive images is set to 1x1 px placeholder image on small devices
    }
  }
  .view-image{
    line-height:0;
    margin:0;
    a{
      display:block;
    }
  }
  .owl-nav{
    // Prev + Next Buttons
    // opacity:0;
    > div{
      position:absolute;
      top:50%;
      text-indent:-9000px;
      height:70px;
      margin-top:-35px !important;
      width:35px;
      padding:0 !important;
      margin:0;
      background:$white !important;
      color:$grey-dark !important;
      animation-duration: 500ms;
      animation-fill-mode: both;
      &::before{
        font-family:$iconfont;
        text-indent:0;
        position:absolute;
        left:0;
        top:0;
        line-height:70px;
        vertical-align:middle;
        width:35px;
        height:70px;
        text-align:center;
        font-size:18px;
      }
      &:hover{
        color:$highlight-sec;
      }
    }
    .owl-prev{
      left:0;
      border-radius:0 70px 70px 0;
      animation-name: fadeOutLeft;
      &::before{
        @include get-ico-only('arrow-thin-left');
      }
    }
    .owl-next{
      right:0;
      border-radius:70px 0 0 70px;
      animation-name: fadeOutRight;
      &::before{
        @include get-ico-only('arrow-thin-right');
      }
    }
  }
  &:hover{
    .owl-nav{
      // opacity:1.0;
      .owl-prev{
        animation-name: fadeInLeft;
      }
      .owl-next{
        animation-name: fadeInRight;
      }
    }
    .owl-dots{
      opacity:1;
    }
  }
  .owl-dots{
    // Dot Navigation
    position:absolute;
    bottom:10px;
    left:0;
    width:100%;
    text-align:center;
    opacity:0;
    transition-duration: 250ms;
    transition-property: opacity;
    .owl-dot{
      display:inline-block;
      background-color:$grey-light;
      width:20px;
      height:20px;
      border-radius:50%;
      margin:0 3px;
      border:5px solid $white;
      span{
        display:none;
      }
      &:hover,
      &.active{
        background-color:$highlight;
      }
    }
  }
}
