
// _base.scss imports _custom.scss (variables and mixins) and other partials.
@import "base";

// Stores reusable objects like buttons, icons, that are used directly as class inside html (Source: _objects.scss)

// webks: Tools > overlay
.webks-tools-page-overlay{
  @include overlay;
}

// =============================================================================
//  Bootstrap Objects (included from libraries/_bootstrap)
// =============================================================================

@import 'libraries/_bootstrap/dropdowns';

// =============================================================================
//  Foundation Dropdown Panes
// =============================================================================

.dropdown-pane{
  background-color: $white;
  border: 1px solid $grey-light;
  border-radius: $border-radius;
  display: block;
  font-size: 1rem;
  padding: $thin-grid;
  position: absolute;
  visibility: hidden;
  width: 300px;
  z-index: 10;
  &.is-open {
    visibility: visible;
  }
  &.tiny { width: 100px; }
  &.small { width: 200px; }
  &.large { width: 400px; }
}

// =============================================================================
//  Buttons
// =============================================================================

.btn,
.button, // This class is used by some modules
.btn-inside a{
  @include btn;
}

// -- COLORS
// -- -- Green
.btn--sucess,
.btn-inside--sucess a{
  @include btn-mod('green');
}

// -- -- Red
.btn--danger,
.btn-inside--danger a{
  @include btn-mod('red');
}

// -- -- Ghost
.btn--ghost,
.btn-inside--ghost a{
  @include btn-mod('ghost');
}

// -- -- Disabled
.btn--disabled,
.btn-inside--disabled a,
button:disabled,
input[type="submit"]:disabled{
  @include btn-mod('disabled');
}

// -- -- Naked (no style, just an link with touch friendly size)
.btn--naked,
.btn-inside--naked a{
  @include btn-mod('naked');
}

// -- Sizes
.btn--xs{
  @include btn-size(xs);
}
.btn--xl{
  @include btn-size(xl);
}
.btn--xxl{
  @include btn-size(xxl);
}
.btn--xxxl{
  @include btn-size(xxxl);
}

// Circle Buttons
.btn--circle{
  text-indent:-9000px;
  text-align:left;
  border-radius:50%;
  height:$icon-size-normal + ($btn-default-v-space * 2);
  width:$icon-size-normal + ($btn-default-v-space * 2);
  line-height:$icon-size-normal + ($btn-default-v-space * 2);
  padding:0 !important;
  &::before{
    float:left;
    text-indent:0;
    font-size:$icon-size-normal;
    vertical-align:middle;
    width:$icon-size-normal + ($btn-default-v-space * 2);
    line-height:$icon-size-normal + ($btn-default-v-space * 2) !important;
    text-align:center;
    margin:0 0 0 -1px !important;
  }
  &.btn--xs{
    height:$icon-size-xs + ($btn-xs-v-space * 2);
    width:$icon-size-xs + ($btn-xs-v-space * 2);
    line-height:$icon-size-xs + ($btn-xs-v-space * 2);
    &::before{
      font-size:$icon-size-xs;
      height:$icon-size-xs + ($btn-xs-v-space * 2);
      width:$icon-size-xs + ($btn-xs-v-space * 2);
      line-height:$icon-size-xs + ($btn-xs-v-space * 2) !important;
    }
  }
  &.btn--xl{
    height:$icon-size-xl + ($btn-xl-v-space * 2);
    width:$icon-size-xl + ($btn-xl-v-space * 2);
    line-height:$icon-size-xl + ($btn-xl-v-space * 2);
    &::before{
      font-size:$icon-size-xl;
      height:$icon-size-xl + ($btn-xl-v-space * 2);
      width:$icon-size-xl + ($btn-xl-v-space * 2);
      line-height:$icon-size-xl + ($btn-xl-v-space * 2) !important;
    }
  }
  &.btn--xxl{
    height:$icon-size-xxl + ($btn-xxl-v-space * 2);
    width:$icon-size-xxl + ($btn-xxl-v-space * 2);
    line-height:$icon-size-xxl + ($btn-xxl-v-space * 2);
    &::before{
      font-size:$icon-size-xxl;
      height:$icon-size-xxl + ($btn-xxl-v-space * 2);
      width:$icon-size-xxl + ($btn-xxl-v-space * 2);
      line-height:$icon-size-xxl + ($btn-xxl-v-space * 2) !important;
    }
  }
  &.btn--xxxl{
    height:$icon-size-xxxl + ($btn-xxxl-v-space * 2);
    width:$icon-size-xxxl + ($btn-xxxl-v-space * 2);
    line-height:$icon-size-xxxl + ($btn-xxxl-v-space * 2);
    &::before{
      font-size:$icon-size-xxxl;
      height:$icon-size-xxxl + ($btn-xxxl-v-space * 2);
      width:$icon-size-xxxl + ($btn-xxxl-v-space * 2);
      line-height:$icon-size-xxxl + ($btn-xxxl-v-space * 2) !important;
    }
  }
}

// -- Full Width Buttons
.btn--block,
.btn-inside--blocked a {
  display:block;
}

// -- Icons inside buttons - for example <a class="btn ico-zoom-in">Buttontext</a>
.btn::before{
  display: inline-block;
  font-family: $iconfont;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  margin-right:5px;
}

// =============================================================================
//  Icons
// =============================================================================

// Iconfont
@font-face {
  font-family: 'iconfont';
  src:url('../fonts/iconfont/iconfont.eot?-cbfawx');
  src:url('../fonts/iconfont/iconfont.eot?#iefix-cbfawx') format('embedded-opentype'),
  url('../fonts/iconfont/iconfont.woff2?-cbfawx') format('woff2'),
  url('../fonts/iconfont/iconfont.woff?-cbfawx') format('woff'),
  url('../fonts/iconfont/iconfont.ttf?-cbfawx') format('truetype'),
  url('../fonts/iconfont/iconfont.svg?-cbfawx#iconfont') format('svg');
  font-weight: normal;
  font-style: normal;
}

// These classes are just for use directly as html class. Dont extend, use the icon mixin instead!
.ico,
.ico-inline{
  @include ico;
}
.ico-seperate{ // Creates an seperate icon out of its own element
  @include ico-replacement;
}
.menu > li > a.ico-inline{
  &::before{
    font-size:16px; // 16px Menu Icons
    font-weight:normal !important;
    vertical-align:middle;
  }
}

// -- Verfuegbare Icons (see _objects.scss)
// TODO: Changed from .ico-bla to .ico--bla -- fix classes defined in contents and js files
@each $name, $ico in $icon-list {
  .ico--#{$name}:before {
    content: $ico;
  }
}

// -- Icon Sizes
.ico--xs{
  &::before{
    font-size:$icon-size-xs;
  }
  &.ico-seperate{
    width:$icon-size-xs;
    height:$icon-size-xs;
    line-height:$icon-size-xs;
  }
}
.ico--normal{
  &::before{
    font-size:$icon-size-normal;
  }
  &.ico-seperate{
    width:$icon-size-normal;
    height:$icon-size-normal;
    line-height:$icon-size-normal;
  }
}
.ico--xl{
  &::before{
    font-size:$icon-size-xl;
  }
  &.ico-seperate{
    width:$icon-size-xl;
    height:$icon-size-xl;
    line-height:$icon-size-xl;
  }
}
.ico--xxl{
  &::before{
    font-size:$icon-size-xxl;
  }
  &.ico-seperate{
    width:$icon-size-xxl;
    height:$icon-size-xxl;
    line-height:$icon-size-xxl;
  }
}
.ico--xxxl{
  &::before{
    font-size:$icon-size-xxxl;
  }
  &.ico-seperate{
    width:$icon-size-xxxl;
    height:$icon-size-xxxl;
    line-height:$icon-size-xxxl;
  }
}


// Icon + Text Combos
.icon-combo{
  text-align:center;
  &__label{
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
    display:block;
  }
  &__icon{
    display:inline-block;
    font-size:$icon-size-xxxl;
  }
}

// Icon specific
.ico--facebook-frame {
  color: #3b5998;
}
.ico--rss-frame{
  color: #ff9f2d;
}


// =============================================================================
//  Box Styles
// =============================================================================

// Default Box Style
.box-style{
  @include box-style;
}
.box-style--info{
  @include box-style-mod('info');
}

// Expandable Blocks
.webks-expandable-container{
  position:relative;
  &.default-hidden:not(.webks-expandable-container--expanded){
    .block-content,
    .webks-expandable-container__content{
      display:none;
    }
  }
  &--expanded{
    .webks-expandable-container__content{
      display:block;
    }
  }
  &__trigger{
    cursor:pointer;
  }
  &__content{
    &:hover{
      z-index:1000 !important; // overlay the full cart flyout
    }
  }
}

// -- Hide them if js isnt loaded / disabled
html:not(.js){
  .block--expandable{
    display:none;
  }
}

// =============================================================================
//  Tiles
// =============================================================================

// We're using BS thumgnails instead of tiles here
$thumbnail-bg: $white; // TODO This variable is getting overridden somewhere.. unclear.

// BS Thumbnails

@import 'libraries/_bootstrap/thumbnails';

// Custom Tiles
.tile{
  color:$white;
  text-shadow: 1px 1px 1px $text-shadow-intensity;

  position:relative;
  &__contents{
    position:absolute;
    bottom:0;
    left:0;
    width:100%;
    z-index:2;
    padding:$thin-grid;
  }
  &__image{
    line-height:0;
  }
  &__overlay-link{
    position:absolute;
    left:0;
    top:0;
    z-index:3;
    width:100%;
    height:100%;
    display:block;
    text-indent:-9000px;
  }
  &__title{
    color:$white;
    margin:0;
    font-size:$font-size-h2;
    line-height:$line-height-small;
  }
  &__price{
    font-size:$font-size-h2;
    line-height:$line-height-small;
    white-space:nowrap;
  }
}

.image-wrapper{
  // Used inside BS Objects & wrapper vor badges and other things placed directly on the image
  position:relative;
  text-align:center;
  min-height:100px;
  > .field{
    // vertically center images
    display:block;
    margin-bottom:0;
    height:100%;
    overflow:hidden;
    > .field-items,
    > .field-items > .field-item,
    > .field-items > .field-item > a{
      display:flex;
      align-items:center;
      justify-content: center;
      height:100%;
    }
    img{
      display:inline;
      vertical-align:middle;
    }
  }
}

.thumbnail{
  .image-wrapper{
    padding-top:$space-thin-v;
  }
  .read-on{
    padding-top:$space-thin-v;
  }
  .field-name-field-produkt-tags{
    margin-top:.75rem;
  }
}

.owl-item{
  // Price Labels inside slider
  .thumbnail{
    border:0 none !important;
    padding:0 !important;
  }
  .image-wrapper{
    margin-top:15px;
    .percent-badge{
      right:0;
    }
  }
}

// Verticaly center owl carousel sliders (onlay with multiple items)
.owlcarousel-settings-einzellogos{
  .owl-stage{
    display:flex;
    align-items:center;
  }
  .owl-item{
    padding-left:5px;
    padding-right:5px;
  }
}

// =============================================================================
//  Cards
// =============================================================================

.card{
  position:relative;
  background:$white;
  z-index:4;
  margin:0 !important;
  transition-property: border-width, border-color, padding, margin;
  transition-duration: 250ms;
  &__image{
    line-height:0;
    margin-bottom:1em;
    .field{
      margin:0 !important;
    }
  }
  &__title{
    font-size:$font-size-h2;
    line-height:1.1;
    .first-word{
      display:block;
    }
  }
  &__subline{
    margin-bottom:$space-thin-v;
    font-weight:$font-weight-normal;
    font-size:$font-small-px;
    line-height:1.1em;
    .field{
      margin:0;
      font-weight:$font-weight-normal;
      font-size:$font-small-px;
    }
  }
  &__overlay-link{
    position:absolute;
    left:0;
    top:0;
    z-index:3;
    width:100%;
    height:100%;
    display:block;
    text-indent:-9000px;
  }
}

// =============================================================================
//  Media Objects
// =============================================================================

.media-object{
  @include media-breakpoint-up(md){
    display:table;
    width:100%;
    display: flex;
  }
  &__image{
    text-align:center;
    line-height:0;
    margin-bottom:$space-thin-v;
    @include media-breakpoint-up(md){
      max-width:35%;
      width:35%;
      margin-bottom:0;
      padding-right:$grid;
      display:table-cell;
      vertical-align: top;
    }
    @include media-breakpoint-up(lg){
      width: $media-object-image-width + $thin-grid;
      min-width: $media-object-image-width + $thin-grid;
    }
    .field{
      margin:0 !important;
      float:none !important;
    }
    a{
      display:block;
      background-color:$image-background-color;
    }
  }
  &__content{
    // Flex fallback
    display:table-cell;
    vertical-align: top;
    // Flexbox
    display: flex;
    flex: 1;
    flex-direction: column;
    @include media-breakpoint-up(md){
      // display: table-cell was here .. makes no sense..
    }
  }
  &__title{
    margin-top:0;
    margin-bottom:$space-thin-v / 2;
    color:$highlight;
    a{
      color:$highlight;
    }
  }
  &__read-on{
    padding-top:$grid-vertical / 3;
    margin-top:auto;
    text-align:right;
  }
  &:hover{
    .media-object__title{
      color:$media-object-title-link-hover;
      a{
        color:$media-object-title-link-hover;
      }
    }
  }
}

// =============================================================================
//  Slideshows
// =============================================================================

// Owl Carousel
@import 'components/owl_carousel';

// =============================================================================
//  Misc Objects
// =============================================================================

/* =============================================================================
 *   Misc Objects
 * ========================================================================== */

/* Price Views */
.price-label{
  padding:16px 15px;
  position:relative;
  display:inline-block;
  background-color:$highlight;
  color:$highlight_text_color;
  border-radius:2px;
  margin-left:8px;
  margin-right:8px;
  line-height:1.15em;
  min-height:32px;
  text-align:left;
  &::before{
    content:" ";
    position:absolute;
    border-radius:50%;
    width:16px;
    height:76%;
    left:0;
    margin-left:-8px;
    top:12%;
    background-color:$highlight;
  }
  &::after{
    content:" " !important;
    position:absolute;
    border-radius:50%;
    width:16px;
    height:36px;
    right:0;
    margin-right:-8px;
    top:12%;
    background-color:$highlight;
    min-height:76%; // for some reason - height dont work.
    // undo clearfix style
    clear:none;
    height:auto !important;
    visibility:visible !important;
    display:block !important;
  }
  .field-label{
    font-size:$font_small !important;
    font-weight:$font_weight_normal !important;
    float:none !important;
    position:relative;
    line-height:1em;
    white-space:nowrap;
    z-index:5;
    margin-bottom:2px;
    display:block !important;
    text-align:left !important;
  }
  .field-items{
    font-weight:$font_weight_bold !important;
    font-size:18px;
    float:none !important;
    position:relative;
    z-index:6;
    line-height:1em;
    white-space:nowrap;
    *{
      display:inline;
    }
  }
  transition-duration: 350ms;
  transition-property: transform;
  transform: translate(15px,-25px) rotate(-12deg);
  &:hover{
    transform: translate(15px,-25px) rotate(-10deg) scale(1.3);
  }
  &--with-uvp{
    // Prive with (striked) UVP price above
    padding-top:6px;
    padding-bottom:11px;
    .field-name-field-angebot-uvp,
    .field-name-field-product-uvp{
      font-size:$font-small;
      .field-label{
        display:inline !important;
      }
      .field-items{
        font-size:$font-small;
        text-decoration:line-through;
      }
    }
  }
}
.percent-badge{
  background:#f96f00;
  color:white;
  height:60px;
  width:60px;
  padding:8px;
  border-radius:50%;
  overflow:hidden;
  text-align:center;
  .field-label{
    font-size:$font-small;
    line-height:$font-small;
    white-space:nowrap;
    margin-bottom:2px;
    margin-top:4px;
  }
  .field-items{
    font-size:20px;
    font-weight:$font_weight_bold;
    white-space:nowrap;
    line-height:$line_height_medium;
  }
}

.title-wrapper{
  overflow:hidden;
}

.image-wrapper{
  // Position on the image
  margin-bottom:55px;
  .price-label{
    position:absolute;
    left:5px;
    bottom:-60px;
  }
  .percent-badge{
    position:absolute;
    right:-10px;
    top:-10px;
  }
}
// -- Grundpreise (einfacher Text unter dem Preis Label)
.field-name-field-product-uvp-grundpreis,
.field-name-field-angebot-preis-grundpreis{
  font-size:$font-small-px;
  margin-bottom:1em;
  *{
    display:inline;
  }
}
.field-name-field-product-uvp-grundpreis + .field-name-field-angebot-preis-grundpreis{
  margin-top:-1em;
}


.equal-height-desc-wrapper{
  overflow:hidden;
}

.owl-item{
  // Price Labels inside slider
  .thumbnail{
    border:0 none !important;
    padding:0 !important;
  }
  .image-wrapper{
    margin-top:15px;
    .percent-badge{
      right:0;
    }
  }
}

// Cutlines
.cutline-top{
  @include cutline('top');
}
.cutline-bottom{
  @include cutline('bottom');
}

// Tags (Tagstyle)
.tagstyle,
.tagstyle ul,
.field-name-field-tags{
  margin:0;
  padding:0;
}
.tagstyle li,
.tagstyle ul li,
.tagstyle .field-item,
.tagstyle--unlinked .field-item,
.tagstyle--unlinked li,
.field-name-field-tags .field-item{
  list-style:none;
  margin: 0 .25em .25em 0;
  padding:0;
  display:inline-block;
}
.tagstyle li a,
.tagstyle ul li a,
.tagstyle .field-item a,
.tagstyle--unlinked .field-item,
.tagstyle--unlinked li,
.field-name-field-tags .field-item a{
  @include btn('ghost', 'xs');
}
.tagstyle--unlinked > li{
  cursor: default;
}

// Subline & Submitted
.subline,
.submitted{
  color: lighten($text-color, 50%);
  font-weight:$font-weight-normal;
  margin-top:0;
  margin-bottom: .5em;
  line-height:1.15em;
  &:not(h3){
    font-size: $font-small;
  }
}
.has-subline{ margin-bottom:0; }

// Lists
.checklist {
  list-style: none outside none;
  padding-left:5px;
  li{
    margin-bottom: .75em;
    padding-left: 33px;
    position: relative;
    &::before {
      border: 2px solid $grey-light;
      border-radius: 50%;
      color: $sucess;
      font-family:$iconfont;
      @include get-ico-only('check');
      font-size: $icon-size-normal;
      left: 0;
      line-height: $icon-size-normal;
      padding: 2px;
      position: absolute;
      top: -2px;
    }
  }
}

// Responsive embed videos
iframe{
  // Minimum fix to prevent crashing the layout
  max-width:100%;
}
.embed-responsive,
.responsive-iframe-wrapper {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;
  &-item,
  iframe,
  embed,
  object,
  video {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    border: 0;
  }
}

// Modifier class for 16:9 aspect ratio
.embed-responsive-16by9,
.responsive-iframe-wrapper--video,
.responsive-iframe-wrapper--map { // this second class is set by our iframe detection script
  padding-bottom: 56.25%;
}

// Modifier class for 4:3 aspect ratio
.embed-responsive-4by3 {
  padding-bottom: 75%;
}

// Maps
.pane--custom-directions-map{
  .pane-title{
    margin-top:0;
    font-size:$font-normal;
  }
}
.custom-directions-map{
  overflow:hidden; // clearfix
  margin-bottom:$grid-vertical;
  &__form-wrapper{
    // Wrapper
  }
  &__form{
    label{
      display:block;
    }
  }
}

// =============================================================================
//  Tooltips (via Popper.js > https://popper.js.org/documentation.html)
// =============================================================================

.popper{
  background:$tooltip-color;
  color:$tooltip-text-color;
  padding:$thin-grid;
  width: 150px;
  text-align:center;
  border-radius:$border-radius-small;
  box-shadow:$shadow-small;
  &[x-placement^="top"] {
    margin-bottom: 5px;
    .popper__arrow {
      border-width: 5px 5px 0;
      border-color: $tooltip-color $transparent $transparent;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  &[x-placement^="bottom"] {
    margin-top: 5px;
    .popper__arrow {
      border-width: 0 5px 5px;
      border-color: $transparent $transparent $tooltip-color;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  &[x-placement^="right"] {
    margin-left: 5px;
    .popper__arrow {
      border-width: 5px 5px 5px 0;
      border-color: transparent $tooltip-color transparent transparent;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }
  &[x-placement^="left"] {
    margin-right: 5px;
    .popper__arrow {
      border-width: 5px 0 5px 5px;
      border-color: transparent transparent transparent $tooltip-color;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }
  a{
    color:$tooltip-text-color;
  }
  .popper__arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
  }
}

// =============================================================================
//  jQuery.UI
// =============================================================================

// uncomment if needed
// @import 'components/jquery_ui';

// =============================================================================
//  Messages
// =============================================================================

// Left and right margin are set by the global gutter width
.message,
div.messages {
  @include message;
}

// Message Types
.message--info,
div.info {
  @include message-mod(info);
}
.message--success,
div.status {
  @include message-mod(success);
}
.message--warning,
div.warning {
  @include message-mod(warning);
}
.message--error,
div.error {
  @include message-mod(error);
}

// -- Other warning & error elements
tr.warning { color:$message-warning-text; }
tr.error { color:$message-error-text; }

// -- Form Errors
input[type="text"].error,
.form-text.error,
textarea.error,
.error {
  border-color:$message-error-text;
}

// Unpublished nodes
.node-unpublished p.unpublished,
.comment-unpublished p.unpublished {
  color: $message-info-text;
  font-size: 75px;
  font-weight: $font-weight-bold;
  height: 0;
  line-height: 1.2em;
  margin: 0;
  padding: 0;
  overflow: visible;
  text-align: center;
  text-transform: uppercase;
  word-wrap: break-word;
}
